//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

// Re-apply the primer styles with higher specificity
// as the OpenProject form styles override them.

.Box
  > ul:not(.op-uc-list)
    margin-left: 0


.FormControl
  label
    margin-bottom: 0
  input
    border-radius: 6px

action-menu
  anchored-position
    ul
      margin-left: 0

ul.tabnav-tabs
  margin-left: 0

/* Remove margin-left: 2rem from Breadcrumbs */
#breadcrumb,
page-header
  ol
    margin-left: 0
  .breadcrumb-item.breadcrumb-item-selected
    a
      pointer-events: none

.op-primer-adjustments__toggle-switch--hidden-loading-indicator
  .ToggleSwitch-statusIcon
    display: none

.Overlay
  &-body_autocomplete_height
    min-height: 300px
