.op-highlighted-input
  padding: 1rem 1rem 0.5rem 0.75rem
  display: flex
  flex-direction: column
  border: 1px solid #cbd5e0
  background: #f7fafc
  border-radius: 4px

  &_active
    border: 1px solid #90cdf4
    background: #ebf8ff
