@import 'helpers'

.op-meeting-agenda-item-form
  display: grid
  grid-template-columns: minmax(0, 100%) 100px 1fr
  grid-template-areas: "title duration presenter" "notes notes notes" "add_note actions actions"
  grid-gap: 8px

  &--actions
    justify-self: end

  @media screen and (max-width: $breakpoint-md)
    grid-template-columns: 100px minmax(0, 100%)
    grid-template-areas: "title title" "duration presenter" "notes notes" "add_note actions"
