//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

@import mixins

.controller-homescreen #content-wrapper
  .widget-box
    box-shadow: none
    border: 1px solid var(--color-border-muted)

    &.upsale
      background: $spot-color-feedback-info-light
      flex-basis: 100%
      border-color: $spot-color-feedback-info-dark

.controller-homescreen #breadcrumb
  display: none

.homescreen--links
  margin: 10px 0
  display: flex
  padding: 20px 20%
  align-items: center
  justify-content: center

  .icon-context:before
    padding-right: 0

  .homescreen--links--item
    flex: 1
    display: block
    text-align: center
    color: var(--content-icon-link-color)

    i::before
      display: block
      margin-bottom: 10px
      font-size: 3rem
      color: var(--content-icon-link-color)

    &:hover,
    &:hover span
      text-decoration: none
      color: var(--accent-color)
      i::before
        color: var(--accent-color)


@media screen and (max-width: $breakpoint-sm)
  .homescreen--links
    padding: 20px
    flex-wrap: wrap

    .homescreen--links--item
      flex: auto
      margin: 20px 0
      width: 50%
