//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

a
  img
    border: 0
  &.sort
    padding-right: 16px
    background-position: 100% 50%
    background-repeat: no-repeat
    &.asc:after
      @include icon-font-common
      @include icon-mixin-sort-ascending
      margin-left: 5px
      font-size: 1.2em
      vertical-align: text-bottom

    &.desc:after
      @include icon-font-common
      @include icon-mixin-sort-descending
      margin-left: 5px
      font-size: 1.2em
      vertical-align: text-bottom

  &.issue.closed, &.issue.closed:hover,
  &.work_package.closed, &.work_package.closed:hover
    text-decoration: line-through

  &.-no-decoration
    color: inherit

    &:hover
      text-decoration: none

a.icon, a.icon-context
  color: var(--content-icon-link-color)
  font-weight: normal
  &.external
    color: var(--accent-color)

a.icon:hover, a.icon-context:hover
  text-decoration: none

#content table th a.no-decoration-on-hover:hover, a.no-decoration-on-hover:hover
  text-decoration: none

.skip-navigation-link
  &:focus
    position: absolute
    width: auto
    height: auto
    overflow: visible
    margin-top: -1.25rem
