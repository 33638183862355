.op-scrollable-tabs
  display: flex
  border-bottom: 1px solid var(--color-border-muted)
  margin-bottom: 1.5rem

  &--tab-container
    flex: 1
    position: relative
    overflow: hidden
    box-sizing: content-box
    height: 40px

  &--actions
    margin: 0 0 0 10px
    list-style: none
    flex: 0
    height: 40px
    // Vertically center icons, space them out
    display: flex
    align-items: center

  &--tab-container
    position: relative
    overflow: hidden

  &--button
    display: block
    width: 20px
    position: absolute
    top: 0px
    line-height: 38px
    background: white
    color: var(--light-gray)
    font-size: 0.7rem
    &_left
      left: 0
      box-shadow: 0px 0px 10px 10px white
      text-align: left
    &_right
      right: 0
      text-align: right
      box-shadow: 0px 0px 10px 10px white
    &:hover
      color: var(--accent-color)
      cursor: pointer
