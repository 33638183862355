//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2024 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

$badge-diameter: 1.25rem
$badge-background: var(--control-checked-color)
$badge-secondary-background: #F1F1F1
$badge-color: var(--font-color-on-primary)

@mixin badge-layout($diameter: $badge-diameter)
  width: $diameter
  height: $diameter

@mixin badge-style($background: $badge-background, $color: $badge-color)
  background: $background

  @if $color == auto
    color: is-light($background)

  @else
    color: $color


.badge
  display: inline-flex
  align-items: center
  justify-content: center
  border-radius: 1000px
  @include badge-layout
  @include badge-style
  width: auto
  min-width: 1.25rem
  padding-left: 0.3rem
  padding-right: 0.3rem

  &.-secondary
    @include badge-style($badge-secondary-background, auto)

  &.-border-only
    border-color: var(--button--border-color)
    color: var(--body-font-color)
    background: transparent
    border-width: 1px
    border-style: solid
